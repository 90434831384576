import React from 'react';
import { CloudinaryImage } from '@cloudinary/url-gen/assets/CloudinaryImage';

import {
  HtmlPictureLayer,
  PictureSources
} from '@cloudinary/html'

interface PictureProps {
  cldImg: CloudinaryImage,
  breakpoints?: number[],
  sources?: PictureSources,
  autoOptimalBreakpoints?: boolean,
  relativeWidth?: number
  [x: string]: any
}

/**
 * @memberOf ReactSDK
 * @type {Component}
 * @description The Cloudinary picture component
 * @prop {CloudinaryImage} transformation Generated by @cloudinary/url-gen
 * @prop {PictureSources} sources sources to generate source tag
 * @example
 *  <caption>
 *  Using custom defined resources.
 * </caption>
 * const img = new CloudinaryImage('sample', { cloudName: 'demo'});
 * const sources = [
 *  {
 *    minWidth:500,
 *    maxWidth:2000,
 *    sizes: "80vw,
 *    image: img
 *  },
 *  {
 *    minWidth:3000,
 *    maxWidth:4000,
 *    sizes: "80vw,
 *    image: img
 *  }];
 *
 * return <AdvancedPicture cldImg={img} sources={sources} />
 */
class AdvancedPicture extends React.Component <PictureProps> {
  pictureRef: React.RefObject<HTMLPictureElement>;
  htmlPictureLayerInstance: HtmlPictureLayer;

  constructor(props: PictureProps) {
    super(props);
    this.pictureRef = React.createRef();
  }

  /**
   * On mount creates a new HTMLPictureLayer instance and initialises with ref to picture element,
   * user generated cloudinaryImage and the sources to be used
   */
  componentDidMount() {
    this.htmlPictureLayerInstance = new HtmlPictureLayer(
      this.pictureRef.current,
      this.props.cldImg,
      this.props.breakpoints,
      this.props.sources,
      this.props.autoOptimalBreakpoints,
      this.props.relativeWidth
    )
  }

  /**
   * On update we update the image instance with the state of user
   * cloudinaryImage and the state of sources
   */
  componentDidUpdate() {
    // call html layer to update the dom
    this.htmlPictureLayerInstance.update(
      this.props.cldImg,
      this.props.breakpoints,
      this.props.sources,
      this.props.autoOptimalBreakpoints,
      this.props.relativeWidth
    );
  }

  render() {
    const {
      cldImg,
      breakpoints,
      sources,
      autoOptimalBreakpoints,
      relativeWidth,
      ...otherProps // Assume any other props are for the base element
    } = this.props;
    return <picture {...otherProps} ref={this.pictureRef} />
  }
}

export { AdvancedPicture };
